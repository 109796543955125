<template>
  <div class="container">
    <div class="header">
      <div>
        <!-- <Cascader
          transfer
          v-model="searchData.farmScene"
          :data="farmScenes"
          change-on-select
          @on-change="cascaderChange"
          placeholder="请选择养殖场"
        ></Cascader> -->
        <label title="仪表名称">皮带秤名称：</label>
        <Input
          v-model="farmName"
          placeholder="请选择养殖场"
          style="width: 200px"
          @on-focus="selFarm"
        ></Input>
      </div>
    </div>
    <div>
      <Row>
        <Col span="18"
          ><p style="font-size: 20px; font-weight: 600">皮带秤统计</p></Col
        >
        <Col span="2">
          <div class="filter-box" style="line-height: 30px">
            <span
              :class="[
                'filter-item',
                fertilizeType == 'day' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('day')"
              style="margin-right: 10px"
              >日</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('month')"
              >月</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('year')"
              >年</span
            >
          </div>
        </Col>
        <Col span="3" push="1">
          <DatePicker
            type="daterange"
            placement="bottom-end"
            placeholder="请选择日期"
            style="width: 200px"
            @on-change="dateChange"
            v-model="time"
          ></DatePicker>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <div
            id="myChart"
            style="width: 100%; height: 300px; margin-bottom: 20px"
          ></div>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Table :columns="columns" :data="data1"></Table>
        </Col>
      </Row>
      <div style="text-align: end">
        <Page
          :total="page.total"
          :pageSize="page.pageSize"
          :pageNo="page.pageNo"
        />
      </div>
    </div>

    <select-sb
      v-model="selModal"
      :mId="mId"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-sb>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import selectSb from "@/components/selectSb";
export default {
  components: {
    selectSb,
  },
  data() {
    return {
      model1: [],
      searchData: {
        farmScene: [],
      },
      // 基地场景
      farmScenes: [],
      fertilizeType: "day",
      cityList: [],
      data: [],
      useAmountData: [],
      time: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      columns: [
          // {
          //   title: "厂名称",
          //   width: 150,
          //   key: "fName",
          // },
          {
            title: "班名称",
            minWidth: 100,
            key: "tS",
          },
          {
            title: "物料名称",
            width: 140,
            align: "center",
            key: "goods",
          },
          {
            title: "仪表名称",
            minWidth: 100,
            key: "mName",
          },
          {
            title: "仪表地址",
            minWidth: 100,
            key: "mAddress",
          },
          {
            title: "设备ID",
            width: 140,
            align: "center",
            key: "mId",
          },
          {
            title: "班累计量",
            minWidth: 100,
            key: "cS",
          },
          {
            title: "日累计量",
            minWidth: 100,
            key: "dS",
          },
          {
            title: "月累计量",
            minWidth: 100,
            key: "mS",
          },
          {
            title: "总累计量",
            minWidth: 130,
            key: "tel",
          },
          {
            title: "瞬时流量",
            minWidth: 100,
            key: "flow",
          },
          {
            title: "瞬时速度",
            minWidth: 100,
            key: "speed",
          },
          {
            title: "故障状态",
            minWidth: 100,
            key: "eInfo",
          },
          {
            title: "仪表数据更新时间",
            minWidth: 170,
            key: "mUpTime",
          }
      ],
      data1: [],
      mId: "",
      farmName: "",
      selModal: false,
      options: {
        siteType: 2,
        species: 3,
      },
    };
  },
  created() {
    this.time.push(
      moment(new Date())
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    );
    this.time.push(moment().format("YYYY-MM-DD"));
    console.log(this.time);
  },
  watch: {
    mId(n) {
      if (!n) return
      this.data = [];
      this.useAmountData = [];
      this.getEchartsList();
      this.getEchartsData();
    }
  },
  methods: {
    selFarm() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.mId = farm.mId;
      this.farmName = farm.mName;
      this.selModal = false;
    },
    monitorCut(type) {
      if (this.fertilizeType == type) return;
      this.fertilizeType = type;
      console.log(this.fertilizeType);
      if (this.fertilizeType == "day") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        console.log(this.time);
      }

      if (this.fertilizeType == "month") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(60, "days")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        this.$forceUpdate()
        console.log(this.time);
      }
      if (this.fertilizeType == "year") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(2, "year")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        console.log(this.time);
      }
      this.getEchartsList();
      this.getEchartsData();
    },
    //初始化Echarts
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        grid: {
          top: 50,
          left: 50,
          right: 60,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          name: "时间段",
          data: this.data,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          name: "单位(吨)",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data: this.useAmountData,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      });
    },
    getEchartsList() {
      if (this.time.length < 2 || !this.time[0] || !this.time[1]) return;
      let type = this.fertilizeType;
      let regionCode = "";
      this.farmScenes.forEach((item) => {
        if (this.mId == item.value) {
          regionCode = item.regionCode;
        }
      });
      // if (this.mId == "") return;
      this.$post(this.$api.BELT_MANAGE.LIST, {
        type: type,
        startTime: moment(this.time[0]).format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment(this.time[1]).format("YYYY-MM-DD") + " 23:59:59",
        mId: this.mId,
      }).then((res) => {
        this.data1 = res.list;
        this.page.total = res.list.length;
        // this.data = Object.keys(res.timeLine);
        // this.useAmountData = Object.values(res.timeLine);
        this.initEcharts();
      });
    },
    getEchartsData(){
      if (this.time.length < 2 || !this.time[0] || !this.time[1]) return;
      let type = this.fertilizeType;
      let regionCode = "";
      this.farmScenes.forEach((item) => {
        if (this.mId == item.value) {
          regionCode = item.regionCode;
        }
      });
        this.$post(this.$api.BELT_MANAGE.ECHARTSSTATICLIST, {
        type: type,
        startTime: moment(this.time[0]).format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment(this.time[1]).format("YYYY-MM-DD") + " 23:59:59",
        mId: this.mId,
      }).then((res) => {
          console.log(res)
          this.data=[]
          this.useAmountData=[]
          res.forEach(item=>{
              this.data.push(item.upTime)
              this.useAmountData.push(item.val)
          })
          console.log(333)
          console.log(this.data)
        this.initEcharts();
      });
    },
    dateChange(val) {
      this.getEchartsList();
      this.getEchartsData();
    },
    // 获取基地场景
    getFarmScene() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        this.farmScenes = res.list.map((item) => ({
          value: item.id,
          label: item.name,
          children: [],
          loading: false,
          regionCode: item.regionCode,
        }));
      });
    },
    // 获取养殖区
    // loadingScenes(item, callback) {
    //   item.loading = true;
    //   this.$post(this.$api.PRODUCTION_AREA.LIST, {
    //     mId: item.value,
    //     pageNo: 1,
    //     pageSize: 9999,
    //     siteType: "2",
    //   })
    //     .then((res) => {
    //       if (!res.list || res.list.length === 0) {
    //         item.children = [
    //           {
    //             disabled: true,
    //             value: -1,
    //             label: "无",
    //           },
    //         ];
    //       } else {
    //         item.children = res.list.map((item) => ({
    //           value: item.id,
    //           label: item.name,
    //         }));
    //       }
    //     })
    //     .finally(() => {
    //       item.loading = false;
    //       callback();
    //     });
    // },
    cascaderChange(value) {
      this.mId = value[0];
      this.getEchartsList();
      this.getEchartsData();
    },
  },
  mounted() {
    this.initEcharts();
    this.getEchartsList();
    this.getEchartsData();
    // this.getFarmScene();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
</style>
